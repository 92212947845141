import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { injectIntl } from "react-intl";
import { API } from "../../../../redux/setupAxios";
import { Card, InputLabel, IconButton, Snackbar, Typography, CssBaseline, Button, TextField, Paper, Grid } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import Swal from 'sweetalert2';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    backgroundImage: `url(${toAbsoluteUrl(
      "/media/background/login.svg"
    )})`
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 35.25,
    borderRadius: 26
  },
}));

const initialValues = {
  token: "",
  newPassword: "",
  confirmPassword: "",
};

function ResetPassword(props) {
  const { dispatch, state } = props;
  const { token } = useParams()
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [notif, setOpenNotif] = React.useState({
    open: false,
    type: '',
    message: ''
  });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const LoginSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Minimum 8 characters")
      .max(50, "Maximum 50 characters")
      .matches(
        /^(?=.*?[0-9])/,
        "Password must contain 1 number"
      )
      .matches(
        /^(?=.*?[A-Z])/,
        "Password must contain 1 uppercase"
      )
      .matches(
        /^(?=.*?[a-z])/,
        "Password must contain 1 lowercase"
      )
      .matches(
        /^(?=.*?[@$!%^-_=+`~*,.:;#?&])/,
        "Password must contain 1 special character"
      )
      .required(
        'New Password is Required'
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], "Password doesn't match")
      .min(8, "Minimum 8 characters")
      .max(50, "Maximum 50 characters")
      .matches(
        /^(?=.*?[0-9])/,
        "Password must contain 1 number"
      )
      .matches(
        /^(?=.*?[A-Z])/,
        "Password must contain 1 uppercase"
      )
      .matches(
        /^(?=.*?[a-z])/,
        "Password must contain 1 lowercase"
      )
      .matches(
        /^(?=.*?[@$!%^-_=+`~*,.:;#?&])/,
        "Password must contain 1 special character"
      )
      .required(
        'Confirm Password is Required'
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      values.token = token;
      setLoading(true);
      setTimeout(() => {
        doResetPassword(values);
      }, 1000);
    },
  });

  const doResetPassword = async (values) => {
    try {
      const response = await API('auth.resetPassword', {
        data: values
      })
      if (response.data.isSuccess && response.data.statusCode === 200) {
        setOpenNotif({
          ...notif,
          open: true,
          type: 'success',
          message: "Password successfully reset..."
        })
        setTimeout(() => {
          history.push('/')
        }, 3000);
      } else {
        setOpenNotif({
          ...notif,
          open: true,
          type: 'error',
          message: response.data.message
        })
      }
      setLoading(false);
    } catch {
      setOpenNotif({
        ...notif,
        open: true,
        type: 'error',
        message: 'Something wrong!'
      })
    }
  }

  return (
    <Grid container component="main" className={classes.root} justifyContent="center" alignItems="center">
      <CssBaseline />
      <Grid item xs={12} sm={5} md={3} component={Paper} elevation={6} style={{ borderRadius: 36 }}>
        <div className={classes.paper}>
          {notif && notif?.open &&
            <>
              <Alert onClose={() => setOpenNotif({ ...notif, open: false })} severity={notif.type}>{notif.message}</Alert>
              <br />
            </>
          }
          <SVG
            src={toAbsoluteUrl(
              "/media/logos/logo-trans-fb.svg"
            )}
          />
          <br />
          <Typography>
            {`Enter your new password`}
          </Typography>
          <br />
          <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={10}>
                <InputLabel>New Password</InputLabel>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  placeholder="New password..."
                  type={showNewPassword ? 'text' : 'password'}
                  id="newPassword"
                  autoComplete="current-password"
                  style={{ backgroundColor: 'rgba(240, 240, 240, 1)', borderRadius: 25 }}
                  {...formik.getFieldProps("newPassword")}
                  className={getInputClasses(
                    "newPassword"
                  )}
                />
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block" style={{ color: 'red' }}>*{formik.errors.newPassword}</div>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  className="btn-block"
                  aria-label="toggle password visibility"
                  style={{ marginTop: 10 }}
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={10}>
                <InputLabel>Confirm Password</InputLabel>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  placeholder="Confirm password..."
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  autoComplete="current-password"
                  style={{ backgroundColor: 'rgba(240, 240, 240, 1)', borderRadius: 25 }}
                  {...formik.getFieldProps("confirmPassword")}
                  className={getInputClasses(
                    "confirmPassword"
                  )}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block" style={{ color: 'red' }}>*{formik.errors.confirmPassword}</div>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  className="btn-block"
                  aria-label="toggle password visibility"
                  style={{ marginTop: 10 }}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={`btn-block ${classes.submit}`}
                  disabled={loading}
                  onClick={() => formik.handleSubmit}
                >
                  Submit
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

const mapStateProps = (state) => ({ state })

export default injectIntl(connect(mapStateProps)(ResetPassword));

import React from "react";
import StorefrontIcon from '@material-ui/icons/Storefront';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';

export const menu = {
    SuperAdministrator: [
        {
            "isManage": false,
            "icon": "fa-chart-line",
            "title": "Dashboard",
            "to": "/dashboard",
            "items": []
        },
        {
            "isManage": true,
            "icon": "fa-user",
            "title": "Admin",
            "to": "/account-management",
            "items": []
        },
        {
            "isManage": false,
            "icon": (isActive) => {
                return (
                    <StorefrontIcon style={isActive ? { color: '#3A4E85' } : { color: '#b5b5c3' }} />
                )
            },
            "title": "Brands & Outlet",
            "items": [
                {
                    "title": "Brands",
                    "to": "/brands",
                    "icon": ""
                },
                {
                    "title": "Outlet",
                    "to": "/outlet",
                    "icon": ""
                },
            ]
        },
        {
            "isManage": false,
            "icon": "fa-clipboard-list",
            "title": "Menu",
            "items": [
                {
                    "title": "Menu List",
                    "to": "/menu-management",
                    "icon": ""
                },
                {
                    "title": "Categories",
                    "to": "/categories-management",
                    "icon": ""
                },
            ]
        },
        {
            "isManage": false,
            "icon": (isActive) => {
                return (
                    <ShoppingCartOutlinedIcon style={isActive ? { color: '#3A4E85' } : { color: '#b5b5c3' }} />
                )
            },
            "title": "Order",
            "items": [
                {
                    "title": "Transaction",
                    "to": "/order-delivery-management-transaction",
                    "icon": ""
                },
                {
                    "title": "History",
                    "to": "/order-delivery-management-history",
                    "icon": ""
                },
                // {
                //     "title": "Refund List",
                //     "to": "/order-delivery-management-refund",
                //     "icon": ""
                // },
            ]
        },
        {
            "isManage": false,
            "icon": "fa-tags",
            "title": "Promo",
            "items": [
                {
                    "title": "Voucher & Banner",
                    "to": "/promo-voucher-and-banner",
                    "icon": ""
                },
                // {
                //     "title": "Bundling",
                //     "to": "/promo-bundling",
                //     "icon": ""
                // },
            ]
        },
        {
            "isManage": true,
            "icon": "fa-users",
            "title": "Customer",
            "to": "/customer-management",
            "items": []
        },
        {
            "isManage": false,
            "icon": "fa-file-alt",
            "title": "Report",
            "to": "/report",
            "items": [
                {
                    "title": "Product",
                    "to": "/report-product",
                    "icon": ""
                },
                {
                    "title": "Outlet",
                    "to": "/report-outlet",
                    "icon": ""
                },
                {
                    "title": "Customer",
                    "to": "/report-customer",
                    "icon": ""
                },
                {
                    "title": "User Transaction",
                    "to": "/report-user-transaction",
                    "icon": ""
                },
                
            ]
        },
        {
            "isManage": false,
            "icon": "fa-comments",
            "title": "Feedback",
            "to": "/feedback-management",
            "items": []
        },
        // {
        //     "isManage": false,
        //     "icon": "fa-th-list",
        //     "title": "Menu Management",
        //     "to": "/menu-management",
        //     "items": []
        // },
        // {
        //     "isManage": false,
        //     "icon": "fa-tags",
        //     "title": "Promo Management",
        //     "to": "/promo-management",
        //     "items": []
        // },
        // {
        //     "isManage": false,
        //     "icon": "fa-comments",
        //     "title": "Feedback Management",
        //     "to": "/feedback-management",
        //     "items": []
        // },
    ],
    Administrator: [
        {
            "isManage": false,
            "icon": "fa-chart-line",
            "title": "Dashboard",
            "to": "/dashboard",
            "items": []
        },
        {
            "isManage": true,
            "icon": "fa-user",
            "title": "Admin Outlet",
            "to": "/account-management",
            "items": []
        },
        {
            "isManage": false,
            "icon": (isActive) => {
                return (
                    <StorefrontIcon style={isActive ? { color: '#3A4E85' } : { color: '#b5b5c3' }} />
                )
            },
            "title": "Brands & Outlet",
            "items": [
                {
                    "title": "Brands",
                    "to": "/brands",
                    "icon": ""
                },
                {
                    "title": "Outlet",
                    "to": "/outlet",
                    "icon": ""
                },
            ]
        },
        {
            "isManage": false,
            "icon": "fa-clipboard-list",
            "title": "Menu",
            "items": [
                {
                    "title": "Menu List",
                    "to": "/menu-management",
                    "icon": ""
                },
                {
                    "title": "Categories",
                    "to": "/categories-management",
                    "icon": ""
                },
            ]
        },
        {
            "isManage": false,
            "icon": (isActive) => {
                return (
                    <ShoppingCartOutlinedIcon style={isActive ? { color: '#3A4E85' } : { color: '#b5b5c3' }} />
                )
            },
            "title": "Order",
            "items": [
                {
                    "title": "Transaction",
                    "to": "/order-delivery-management-transaction",
                    "icon": ""
                },
                {
                    "title": "History",
                    "to": "/order-delivery-management-history",
                    "icon": ""
                },
                // {
                //     "title": "Refund List",
                //     "to": "/order-delivery-management-refund",
                //     "icon": ""
                // },
            ]
        },
        {
            "isManage": false,
            "icon": "fa-tags",
            "title": "Promo",
            "to": "/promo-voucher-and-banner",
            "items": []
        },
        {
            "isManage": true,
            "icon": "fa-users",
            "title": "Customer",
            "to": "/customer-management",
            "items": []
        },
        {
            "isManage": false,
            "icon": "fa-file-alt",
            "title": "Report",
            "to": "/report",
            "items": []
        },
        {
            "isManage": false,
            "icon": "fa-comments",
            "title": "Feedback",
            "to": "/feedback-management",
            "items": []
        },
        // {
        //     "isManage": false,
        //     "icon": "fa-th-list",
        //     "title": "Menu Management",
        //     "to": "/menu-management",
        //     "items": []
        // },
        // {
        //     "isManage": false,
        //     "icon": "fa-tags",
        //     "title": "Promo Management",
        //     "to": "/promo-management",
        //     "items": []
        // },
        // {
        //     "isManage": false,
        //     "icon": "fa-comments",
        //     "title": "Feedback Management",
        //     "to": "/feedback-management",
        //     "items": []
        // },
    ],
}
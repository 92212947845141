import axios from 'axios';
import store from './store';
import endpoint from './endpoints';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
})
apiClient.interceptors.request.use(
  config => {
    const {
      auth: { authToken }
    } = store.getState();

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  err => Promise.reject(err)
);

// apiClient.interceptors.response.use(
//   (response) => {
//     Promise.resolve(response)
//   },
//   (error) => {
//     const originalRequest = error.config;
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const { auth } = store.getState();
//       getRefreshToken(originalRequest, auth);
//     }
//   }
// )

// const getRefreshToken = async (originalRequest, auth) => {
//   try {
//     const response = await API('auth.refreshToken', {
//       data: { refreshToken: auth.refreshToken }
//     })
//     if (response.data.isSuccess && response.data.statusCode === 200) {
//       apiClient.defaults.headers.common['Authorization'] = `Bearer ${response?.data?.payload?.token}`;
//       store.dispatch({
//         type: 'LOGIN',
//         payload: {
//           ...auth,
//           scheme: response?.data?.payload?.scheme || null,
//           authToken: response?.data?.payload?.token || null,
//           refreshToken: response?.data?.payload?.refreshToken || null,
//           refreshTokenExpiredOn: response?.data?.payload?.refreshTokenExpiredOn || null,
//         }
//       })
//       Promise.resolve(apiClient.request(originalRequest))
//     }
//   } catch (err) {
//     Promise.reject(err)
//   }
// }

const urlBuilder = ({ query, urlApi }) => {
  if (query)
    return Object.keys(query).reduce((url, key) => url.replace(`:${key}`, query[key]), urlApi)
  return urlApi
}

export const API = (...args) => {
  const [urlMethod, params] = args
  const [name, method] = urlMethod.split('.')

  const context = { ...endpoint[name][method], ...params }
  context.url = urlBuilder({ ...params, urlApi: endpoint[name][method].url })

  return apiClient(context)
}
import React from "react";
import { useHistory } from "react-router-dom";
import IdleTimer from 'react-idle-timer';
import Swal from "sweetalert2";

function IdleTime() {
    const idleTimerRef = React.useRef(null)
    const history = useHistory();
    const seconds = 1800;

    const onIdle = () => {
        history.push('/logout')
        Swal.fire({
            title: 'Info',
            text: `You are idle for ${seconds / 60} minutes`,
            confirmButtonText: "Yes",
        })
    }

    return (
        <div>
            <IdleTimer ref={idleTimerRef} timeout={seconds * 1000} onIdle={onIdle}></IdleTimer>
        </div>
    );
};

export default IdleTime;

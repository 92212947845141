import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

// CMS
const AccountManagement = lazy(() =>
  import("./pages/account-management")
);
const AdminOutlet = lazy(() =>
  import("./pages/brands-and-outlet-management/admin-outlet")
);
const BrandsManagement = lazy(() =>
  import("./pages/brands-and-outlet-management/brands")
);
const OutletManagement = lazy(() =>
  import("./pages/brands-and-outlet-management/outlet-and-admin")
);
const CustomerManagement = lazy(() =>
  import("./pages/customer-management")
);
const FeedbackManagement = lazy(() =>
  import("./pages/feedback-management")
);
const MenuManagementERP = lazy(() =>
  import("./pages/menu-management-erp")
);
const CategoriesManagement = lazy(() =>
  import("./pages/categories-management")
);
const OrderDeliveryManagementTransaction = lazy(() =>
  import("./pages/order-delivery-management/transaction")
);
const OrderDeliveryManagementHistory = lazy(() =>
  import("./pages/order-delivery-management/history")
);
const OrderDeliveryManagementRefund = lazy(() =>
  import("./pages/order-delivery-management/refund")
);
const PromoVoucherAndBanner = lazy(() =>
  import("./pages/promo-management/voucher-and-banner")
);
const PromoBundling = lazy(() =>
  import("./pages/promo-management/bundling")
);
const Report = lazy(() =>
  import("./pages/report")
);
const ReportProduct = lazy(() =>
  import("./pages/report/product")
);
const ReportOutlet = lazy(() =>
  import("./pages/report/outlet")
);
const ReportCustomer = lazy(() =>
  import("./pages/report/report-customer")
);
const ReportUserTransaction = lazy(() =>
  import("./pages/report/user-transaction")
);


function BasePage({ auth }) {
  const { user } = auth
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /home. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* CMS */}
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/account-management" component={user?.roleName === 'SuperAdministrator' ? AccountManagement : AdminOutlet} />
        <ContentRoute path="/brands" component={BrandsManagement} />
        <ContentRoute path="/outlet" component={OutletManagement} />
        <ContentRoute path="/menu-management" component={MenuManagementERP} />
        <ContentRoute path="/categories-management" component={CategoriesManagement} />
        <ContentRoute path="/customer-management" component={CustomerManagement} />
        <ContentRoute path="/order-delivery-management-transaction" component={OrderDeliveryManagementTransaction} />
        <ContentRoute path="/order-delivery-management-history" component={OrderDeliveryManagementHistory} />
        <ContentRoute path="/order-delivery-management-refund" component={OrderDeliveryManagementRefund} />
        <ContentRoute path="/promo-voucher-and-banner" component={PromoVoucherAndBanner} />
        <ContentRoute path="/promo-bundling" component={PromoBundling} />
        <ContentRoute path="/feedback-management" component={FeedbackManagement} />
        <ContentRoute path="/report" component={Report} />
        <ContentRoute path="/report-product" component={ReportProduct} />
        <ContentRoute path="/report-outlet" component={ReportOutlet} />
        <ContentRoute path="/report-customer" component={ReportCustomer} />
        <ContentRoute path="/report-user-transaction" component={ReportUserTransaction} />

        {/* Default */}
        <Route path="/error" component={ErrorsPage} />
        <Redirect to="error/error-v3" />
      </Switch>
    </Suspense>
  );
}

const mapStateProps = (state) => (state)

export default connect(mapStateProps)(BasePage)
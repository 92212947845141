/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div className="d-flex flex-column-fluid flex-center mt-lg-0">
          <Switch>
            <ContentRoute path="/auth/login" component={Login} />
            <ContentRoute
              path="/auth/registration"
              component={Registration}
            />
            <ContentRoute
              path="/auth/forgot-password"
              component={ForgotPassword}
            />
            <ContentRoute
              path="/auth/reset-password/:token"
              component={ResetPassword}
            />
            <Redirect from="/auth" exact={true} to="/auth/login" />
            <Redirect to="/auth/login" />
          </Switch>
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}

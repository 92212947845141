export default {
  auth: {
    login: {
      method: 'post',
      url: '/v1/users/login',
    },
    refreshToken: {
      method: 'post',
      url: '/v1/users/refresh-token',
    },
    user: {
      method: 'get',
      url: '/v1/users/me',
    },
    forgotPassword: {
      method: 'post',
      url: '/v1/users/forgot-password/:userName',
    },
    resetPassword: {
      method: 'post',
      url: '/v1/users/reset-password',
    },
  },
  user: {
    create: {
      method: 'post',
      url: '/v1/users',
    },
    createOutlet: {
      method: 'post',
      url: '/v1/users/outlet',
    },
    readOutlet: {
      method: 'get',
      url: '/v1/users/outlet?p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    read: {
      method: 'get',
      url: '/v1/users?p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    update: {
      method: 'patch',
      url: '/v1/users/:userId',
    },
    updateOutlet: {
      method: 'patch',
      url: '/v1/users/outlet/:userId',
    },
    delete: {
      method: 'delete',
      url: '/v1/users/:userId',
    },
    detail: {
      method: 'get',
      url: '/v1/users/:userId',
    },
    changePassword: {
      method: 'patch',
      url: '/v1/users/change-password',
    },
  },
  brands: {
    create: {
      method: 'post',
      url: '/v1/brand',
    },
    readFilter: {
      method: 'get',
      url: '/v1/brand/select',
    },
    read: {
      method: 'get',
      url: '/v1/brand?p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    update: {
      method: 'patch',
      url: '/v1/brand/:brandId',
    },
    delete: {
      method: 'delete',
      url: '/brand/:brandId',
    },
    detail: {
      method: 'get',
      url: '/brand/:brandId',
    },
  },
  outlets: {
    create: {
      method: 'post',
      url: '/v1/outlets',
    },
    readFilter: {
      method: 'get',
      url: '/v1/outlets/select?id=:id',
    },
    read: {
      method: 'get',
      url: '/v1/outlets?p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search&outletType=:outletType',
    },
    update: {
      method: 'patch',
      url: '/v1/outlets/:outletId',
    },
    deleteSuperstore: {
      method: 'delete',
      url: '/v1/outlets/:outletId',
    },
    detail: {
      method: 'get',
      url: '/v1/outlets/:outletId',
    },
    readChildSuperStore: {
      method: 'get',
      url: '/v1/outlets/non-superstore?p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    createSuperStore: {
      method: 'post',
      url: '/v1/outlets/superstore',
    },
    updateSuperStore: {
      method: 'patch',
      url: '/v1/outlets/superstores/:outletId',
    },
    dialCode: {
      method: 'get',
      url: '/v1/outlets/dial-code',
    },
  },
  uploadFiles: {
    post: {
      method: 'post',
      url: '/v1/file/:fileName',
    },
    get: {
      method: 'get',
      url: '/v1/file/:fileName',
    }
  },
  menus: {
    createCategory: {
      method: 'post',
      url: '/v1/products/categories',
    },
    read: {
      method: 'get',
      url: '/v1/products?p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search&brandName=:brandName&outletName=:outletName',
    },
    readCategory: {
      method: 'get',
      url: '/v1/products/categories?p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    readAddOns: {
      method: 'get',
      url: '/v1/products/add-ons?outletId=:outletId',
    },
    update: {
      method: 'patch',
      url: '/v1/products/:productId',
    },
    updateCategory: {
      method: 'patch',
      url: '/v1/products/categories/:productCategoryId',
    },
    updateCategoryStatus: {
      method: 'patch',
      url: '/v1/products/categories/status/:productCategoryId/:status/:sequence',
    },
    updateStock: {
      method: 'patch',
      url: '/v1/products/stock',
    },
    updateStatus: {
      method: 'patch',
      url: '/v1/products/status/:productId/:status',
    },
    deleteCategory: {
      method: 'delete',
      url: '/v1/products/:productCategoryId',
    }
  },
  order: {
    read: {
      method: 'get',
      url: '/v1/order/list-order?startDate=:startDate&endDate=:endDate&type=:type&p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search&methodOrder=:methodOrder',
    },
    readHistory: {
      method: 'get',
      url: '/v1/order/history-order?startDate=:startDate&endDate=:endDate&type=:type&p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search&methodOrder=:methodOrder',
    },
    detail: {
      method: 'get',
      url: '/v1/order/detail/:orderDetailId?isRefund=:isRefund',
    },
    detailRefund: {
      method: 'get',
      url: '/v1/order/detail-refund/:transactionId',
    },
    readRefund: {
      method: 'get',
      url: '/v1/order/list-refund?startDate=:startDate&endDate=:endDate&p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    readLogPayment: {
      method: 'get',
      url: 'v1/order/detail/:orderDetailId/transaction',
    },
  },
  promoVoucher: {
    create: {
      method: 'post',
      url: '/v1/promo',
    },
    read: {
      method: 'get',
      url: '/v1/promo?p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    update: {
      method: 'patch',
      url: '/v1/promo/:promoId',
    },
    delete: {
      method: 'delete',
      url: '/v1/promo/:promoId',
    },
    updateStatus: {
      method: 'patch',
      url: '/v1/promo/:promoId/:status',
    },
    pushNotif: {
      method: 'patch',
      url: '/v1/promo/notification',
    },
    changeDate: {
      method: 'patch',
      url: '/v1/promo/change-date/:promoId',
    },
  },
  promoBanner: {
    create: {
      method: 'post',
      url: '/v1/promo/banner',
    },
    read: {
      method: 'get',
      url: '/v1/promo/banner?p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    update: {
      method: 'patch',
      url: '/v1/promo/banner/:promoId',
    },
    delete: {
      method: 'delete',
      url: '/v1/promo/banner/:promoId',
    },
    updateStatus: {
      method: 'patch',
      url: '/v1/promo/banner/:promoId/:status',
    },
    pushNotif: {
      method: 'patch',
      url: '/v1/promo/banner-notification',
    },
  },
  promoBundling: {
    create: {
      method: 'post',
      url: '/v1/promo/bundling',
    },
    read: {
      method: 'get',
      url: '/v1/promo/bundling?p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    update: {
      method: 'patch',
      url: '/v1/promo/bundling/:bundlingPromoId',
    },
    delete: {
      method: 'delete',
      url: '/v1/promo/bundling/:bundlingPromoId',
    },
    updateStatus: {
      method: 'patch',
      url: '/v1/promo/bundling/:bundlingPromoId/:status',
    },
    pushNotif: {
      method: 'patch',
      url: '/v1/promo/bundling-notification',
    },
  },
  customer: {
    read: {
      method: 'get',
      url: '/v1/users/customer?p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    detail: {
      method: 'get',
      url: '/v1/users/customer/:userId',
    },
  },
  feedback: {
    read: {
      method: 'get',
      url: '/v1/feedbacks?p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    detail: {
      method: 'get',
      url: '/v1/feedbacks/:outletFeedbackId',
    },
  },
  report: {
    readItem: {
      method: 'get',
      url: '/report/item?startDate=:startDate&endDate=:endDate&p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    readReportItem: {
      method: 'get',
      url: '/report/export-item?startDate=:startDate&endDate=:endDate&search=:search',
    },
    readOutlet: {
      method: 'get',
      url: '/report/outlet?startDate=:startDate&endDate=:endDate&p=:page&s=:size&sortBy=:sort&orderType=:order&q=:search',
    },
    readReportOutlet: {
      method: 'get',
      url: '/report/export-outlet?startDate=:startDate&endDate=:endDate&search=:search',
    },
    readReportCustomer: {
      method: 'get',
      url: '/report/customers?startDate=:startDate&endDate=:endDate&p=:page&s=:size&orderBy=:sort&orderType=:order&search=:search',
    },
    readReportCustomerDownload: {
      method: 'get',
      url: '/report/customers/download?startDate=:startDate&endDate=:endDate&search=:search',
    },
    readTotalCustomerRegistration: {
      method: 'get',
      url: '/report/customers/total-registration?startDate=:startDate&endDate=:endDate&search=:search',
    },
    readReportUserTransaction: {
      method: 'get',
      url: '/report/user-transactions?startDate=:startDate&endDate=:endDate&p=:page&s=:size&sortBy=:sort&orderType=:order&search=:search',
    },
    readReportUserTransactionDownload: {
      method: 'get',
      url: '/report/user-transactions/download?startDate=:startDate&endDate=:endDate&p=:page&s=:size&sortBy=:sort&orderType=:order&search=:search',
    },
    readTotalCustomerTransaction: {
      method: 'get',
      url: '/report/user-transactions/total-customer?startDate=:startDate&endDate=:endDate&search=:search',
    },
    readTotalTransaction: {
      method: 'get',
      url: '/report/user-transactions/total-transaction?startDate=:startDate&endDate=:endDate&search=:search',
    },
  },
}

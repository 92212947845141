import React from 'react';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Dialog, DialogContent, Grid, Tooltip, Menu, MenuItem as ItemMenu, Typography, Toolbar, Fade, AppBar, Drawer, List, CssBaseline, Divider, Collapse, IconButton, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountCircle from '@material-ui/icons/AccountCircle';
import BasePage from '../../../../app/BasePage';
import { menu } from "./menu";
import { hasChildren } from "./utils";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ChangePassword from "../../../../app/modules/Auth/pages/ChangePassword";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { version } from '../../../../../package.json';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  menuButton: {
    marginRight: 6,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 'auto'
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    marginTop: 60,
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
  drawerLogo: {
    height: 128
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
function MaterialDrawer(state) {
  const { pathname } = state?.location;
  const { user } = state?.auth;
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed" className={classes.appBar}
      >
        <Toolbar>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" noWrap className={classes.title} />
          <div>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Typography>{`Hello, `}<span style={{ fontWeight: 600 }}>{user?.fullname || 'Anonymous'}</span></Typography>
              </Grid>
              <Grid item style={{ paddingLeft: 8 }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  keepMounted
                  TransitionComponent={Fade}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <ItemMenu>
                    <Grid container justifyContent="center" style={{ fontWeight: 'bold' }}>
                      {user?.roleName}
                    </Grid>
                  </ItemMenu>
                  <Divider />
                  <ItemMenu onClick={() => setOpenModal(true)}>
                    <ListItemIcon style={{ marginRight: '-20px' }}>
                      <VpnKeyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                  </ItemMenu>
                  <ItemMenu onClick={() => history.push('/logout')}>
                    <ListItemIcon style={{ marginRight: '-20px' }}>
                      <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" />
                  </ItemMenu>
                </Menu>
              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <Grid className={classes.drawerLogo} container justifyContent="center" alignItems="center">
          <Grid item>
            <SVG
              src={toAbsoluteUrl(
                "/media/logos/logo-trans-fb.svg"
              )}
            />
            <Typography className="mt-4" style={{ textAlign: 'center', fontWeight: 'lighter', color: 'gray', cursor: 'default' }}>
              {`Admin Dashboard`}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            margin: '0px 10px 5px 0px'
          }}
        >
          <Typography>Version {version}</Typography>
      </Grid>
        {/* Hardcode menu roles */}
        <Grid style={{ overflowY: 'auto' }}>
          {user?.roleName && menu[user?.roleName].map((item, key) => {
            return (
              !item.isManage &&
              <MenuItem key={key} item={item} pathname={pathname} />
            )
          })}
          <br />
        </Grid>
        <br />
        <Typography style={{ fontWeight: 'lighter', color: 'gray', cursor: 'default', margin: '0px 0px 10px 26px' }}>Manage</Typography>
        {user?.roleName && menu[user?.roleName].map((item, key) => {
          return (
            item.isManage &&
            <MenuItem key={key} item={item} pathname={pathname} />
          )
        })}
      </Drawer>
      <main className={classes.content}>
        {/* <Layout> */}
        <BasePage />
        {/* </Layout> */}
      </main>
      {/* Modal */}
      <Dialog fullWidth maxWidth="sm" open={openModal} onClose={(event, reason) => reason === 'escapeKeyDown' && setOpenModal(false)} aria-labelledby="form-create">
        <DialogTitle onClose={() => setOpenModal(false)} id="title">Change Password</DialogTitle>
        <Divider light />
        <DialogContent>
          <ChangePassword
            handleCancel={() => setOpenModal(false)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const MenuItem = ({ item, pathname }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  item.title = item.title.length > 22 ? `${item.title.substr(0, 22)}...` : item.title;
  return <Component item={item} pathname={pathname} />;
};

const SingleLevel = ({ item, pathname }) => {
  const history = useHistory();
  return (
    <ListItem style={pathname === item.to ? { backgroundColor: '#f4f4f4', borderLeft: '8px solid #3A4E85', color: '#3A4E85' } : {}} button onClick={() => history.push(item.to)}>
      <ListItemIcon style={{ paddingLeft: 12 }}>
        {(typeof item.icon) === 'string' ?
          <i style={pathname === item.to ? { color: '#3A4E85' } : {}} className={`fa ${item.icon}`} />
          :
          item.icon(pathname === item.to)
        }
      </ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
};

const MultiLevel = ({ item, pathname }) => {
  const { items: children } = item;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemIcon style={{ paddingLeft: 12 }}>
          {(typeof item.icon) === 'string' ?
            <i className={`fa ${item.icon}`} />
            :
            item.icon()
          }
        </ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} pathname={pathname} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};


const mapStateToProps = (state) => (state)

export default connect(mapStateToProps)(MaterialDrawer)